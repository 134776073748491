<template>
  <div>
    <header>
      <!-- 头部内容 -->
    </header>
    <el-menu mode="horizontal" class="header-menu">
      <el-menu-item index="1">
        <router-link class="daoHang" to="/home">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-a-jiafangzijianzhu"></use>
        </svg>
        首页
        </router-link>
      </el-menu-item>
      <el-menu-item index="2">
        <router-link class="daoHang" to="/breastfeeding">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-tianjiajilu-xinai"></use>
        </svg>
        储存
        </router-link>
      </el-menu-item>
      <el-menu-item index="3">
        <router-link class="daoHang" to="/bottleFeeding">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-yingerhenai"></use>
        </svg>
        喂奶
        </router-link>
      </el-menu-item>
      <el-menu-item index="4">
        <router-link class="daoHang" to="/kiss">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-muruqinwei"></use>
        </svg>
        亲喂
        </router-link>
      </el-menu-item>
    </el-menu>
    <main>
      <router-view></router-view>
    </main>
    <footer>
      <!-- 底部内容 -->
    </footer>
  </div>
</template>

<script>
export default {
  name: 'LayoutIndex'
}
</script>

<style scoped>
.header-menu {
  display: flex;
  justify-content: center;
  box-shadow: none;
}

.daoHang {
  font-size: 18px; /* 适中字体大小 */
  font-weight: 500;
  text-decoration: none;
}

.icon {
  width: 1.1em;
  height: 1.1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
