// 喂奶记录
import request from '@/utils/request'

// 添加喂奶
export function weinai (data) {
  return request({
    url: '/feedingRecords/weinai',
    method: 'POST',
    data
  })
}

// 获取页面数据
export function renderPage (type) {
  return request({
    url: '/feedingRecords/renderPage',
    params: {
      type
    }
  })
}

// 删除记录
export function del (id) {
  return request({
    url: `/feedingRecords/deleteFeedingRecord/${id}`,
    method: 'DELETE'
  })
}

// 编辑数据
export function btnEditRecord (data) {
  return request({
    url: `/feedingRecords/editRecord/${data.id}`,
    method: 'PUT',
    data
  })
}

// 获取charts统计数据
export function getEchartsRender (type) {
  return request({
    url: '/feedingRecords/echartsRender/',
    params: {
      type
    }
  })
}
