import axios from 'axios'
import Cookies from 'js-cookie'

// 这是单独的文件夹，里面的 this ！= vue 原型实例的，所以使用message需要导入
import { Message } from 'element-ui'

const instance = axios.create({
  baseURL: '/api',
  timeout: 10000,
  headers: { 'Content-Type': 'application/json' }
})

// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // 拿到 token
  // const token = localStorage.getItem('userToken05869.8U*#?90t543@3i%>')
  const token = Cookies.get('AJSFb;.ASdajsA05869.8U*#?90t543@3i%>')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  return response.data
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  if (error.response) {
    if (error.message !== null && error.code !== 'ERR_BAD_REQUEST') {
      if (error.message === 'Request failed with status code 500') {
        Message.error('⚠️ 服务器繁忙，请稍后重试 ')
        return Promise.reject(error)
      }
      Message.error(error.message)
      return Promise.reject(error)
    }
    if (error.response.status === 403 && error.code === 'ERR_BAD_REQUEST') {
      Message.error('权限出问题喽，请重新登陆～ 🌈')
      // localStorage.removeItem('userToken05869.8U*#?90t543@3i%>')
      Cookies.remove('AJSFb;.ASdajsA05869.8U*#?90t543@3i%>')
      return Promise.reject(error)
    }
    if (error.response !== null && error.response.data !== null) {
      console.dir(error)
      Message.error(error.response.data.message)
      return Promise.reject(error)
    }
    Message.error(error.message + '⚠️')
  }
  return Promise.reject(error)
})

export default instance
