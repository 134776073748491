import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookies from 'js-cookie'

import Layout from '@/layout/index.vue'
import BottleFeeding from '@/views/BottleFeeding'
import Breastfeeding from '@/views/Breastfeeding'
import Kiss from '@/views/Kiss'
import Home from '@/views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  // 默认进入喂奶记录
  {
    path: '/',
    name: 'LayoutIndex',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'HomeIndex',
        component: Home,
        meta: { requiresAuth: true, class: 'homeIndex-style' }
      },
      {
        path: 'bottleFeeding',
        name: 'BottleFeeding',
        component: BottleFeeding,
        meta: { requiresAuth: true, class: 'bottleFeeding-style' }
      },
      {
        path: 'breastfeeding',
        name: 'BreastfeedingIndex',
        component: Breastfeeding,
        meta: { requiresAuth: true, class: 'breastfeeding-style' }
      },
      {
        path: 'kiss',
        name: 'KissIndex',
        component: Kiss,
        meta: { requiresAuth: true, class: 'kiss-style' }
      }]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const whiteList = ['/login']

// 全局前置守卫 to到哪里去 from从来里来
router.beforeEach((to, from, next) => {
  if (whiteList.includes(to.path)) {
    next() // 如果在白名单中，直接放行
    return // 使用return确保后面的代码不会被执行
  }
  // const token = localStorage.getItem('userToken05869.8U*#?90t543@3i%>') // 获取token
  const token = Cookies.get('AJSFb;.ASdajsA05869.8U*#?90t543@3i%>')

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (token) {
      next() // 如果需要权限且token存在，放行
    } else {
      next({ path: '/login' }) // 如果token不存在，重定向到登录页
    }
  } else {
    next() // 如果不需要权限，直接放行
  }
})

export default router
