<template>
  <div :class="['breastfeeding-container', pageClass]">
    <!-- 天气 -->
    <div class="weather-card">
      <div class="weather-details-display">
        <span>{{ myWeather.reporttime.slice(0, 10) }}</span>
        <span>{{ dayOfWeek }}</span>
        <span>{{ myWeather.temperature }}°C</span>
        <span>湿度 {{ myWeather.humidity }}%</span>
        <span>{{ myWeather.winddirection }}风{{ myWeather.windpower }}级</span>
        <span>
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="getWeatherIcon(myWeather.weather)"></use>
          </svg>
          {{ myWeather.weather }}</span
        >
      </div>
    </div>
    <!-- 生日 -->
    <div class="timer">
      <div class="time-unit">
        <span class="time-value">{{ birthdayTime.days }}</span>
        <span class="time-label">DAYS</span>
      </div>
      <div class="time-unit">
        <span class="time-value">{{ birthdayTime.hours }}</span>
        <span class="time-label">HOURS</span>
      </div>
      <div class="time-unit">
        <span class="time-value">{{ birthdayTime.minutes }}</span>
        <span class="time-label">MINUTES</span>
      </div>
      <div class="time-unit">
        <span class="time-value">{{ birthdayTime.seconds }}</span>
        <span class="time-label">SECONDS</span>
      </div>
    </div>
    <!-- Echars -->
    <div class="chart-container">
      <div ref="chartDomOne" style="width: 100%; height: 300px"></div>
    </div>
    <!-- 添加时间线按钮 -->
    <div class="add-button">
      <el-button
        type="danger"
        icon="el-icon-s-check"
        plain
        round
        @click="addBtn"
        style="width: 100%"
        >添加记录</el-button
      >
    </div>
    <!-- 时间线 -->
    <div class="timeLin" v-infinite-scroll="loadMoreData" infinite-scroll-disabled="isBusy" infinite-scroll-distance="10px" style="overflow:auto">
      <!-- 身高 -->
      <div class="left">
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in dateTimeHeightList"
            :key="index"
            icon=""
            color="#75f2a4"
            size="large"
            :timestamp="item.babyDate"
            style="cursor: pointer;"
          >
            <span @click="timeLinClick(item)" style="fontSize:26px;">{{ item.babyValue }}</span> <span style="fontSize:8px">cm</span>
          </el-timeline-item>
        </el-timeline>
      </div>
      <div>
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in dateTimeWeightList"
            :key="index"
            icon=""
            color="red"
            size="large"
            :timestamp="item.babyDate"
            style="cursor: pointer;"
          >
            <span @click="timeLinClick(item)" style="fontSize:26px">{{ item.babyValue }}</span> <span style="fontSize:8px;color: #f38b95;">{{ item.babyValue/2 }} KG</span>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
    <!-- 添加日记按钮 -->
    <div class="add-button">
      <el-button
        type="success"
        icon="el-icon-medal-1"
        plain
        round
        @click="baobaorijiAddBtn"
        style="width: 100%"
        >宝宝日记</el-button
      >
    </div>
    <!-- 日记线 -->
    <div class="block">
      <el-timeline>
        <el-timeline-item timestamp="2018/4/12" placement="top">
          <el-card>
            <h4>更新 Github 模板</h4>
            <p>王小虎 提交于 2018/4/12 20:46</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2018/4/3" placement="top">
          <el-card>
            <h4>更新 Github 模板</h4>
            <p>王小虎 提交于 2018/4/3 20:46</p>
          </el-card>
        </el-timeline-item>
        <el-timeline-item timestamp="2018/4/2" placement="top">
          <el-card>
            <h4>更新 Github 模板</h4>
            <p>王小虎 提交于 2018/4/2 20:46</p>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </div>
    <!-- 置顶 -->
    <template>
      <div>
        <el-backtop>
          <i class="el-icon-caret-top"></i>
        </el-backtop>
      </div>
    </template>

    <!-- 时间线_抽屉 -->
    <el-drawer
      :style="{ height: '150vh' }"
      :title="timeDateTitle"
      :visible.sync="drawer"
      direction="ttb"
      :before-close="handleClose"
      :destroy-on-close= "true"
    >
      <el-form
        :model="timeDateForm"
        :rules="timeDateFormRules"
        ref="timeForm"
        label-width="80px"
      >
        <el-form-item label="时间" required>
          <el-col :span="11">
            <el-form-item prop="date">
              <el-date-picker
              v-model="timeDateForm.date"
              type="date"
              placeholder="选择日期"
              size="mini"
              style="width: 90%"
              value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="1" style="margin-left: -12px;">-</el-col>
          <el-col :span="11">
            <el-form-item prop="time">
              <el-time-picker
              v-model="timeDateForm.time"
              placeholder="选择时间"
              size="mini"
              style="width: 90%"
              value-format="HH:mm:ss"
              ></el-time-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select
            v-model="timeDateForm.type"
            size="mini"
            placeholder="请选择身高或体重"
          >
            <el-option label="身高" value="1"></el-option>
            <el-option label="体重" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数值" prop="value">
          <el-input
            v-model="timeDateForm.value"
            size="mini"
            placeholder="请填写宝宝的数据"
            style="width: 55%"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-row type="flex" justify="center">
        <el-col :span="6">
          <el-button type="danger" round @click="handleClose">取消</el-button>
        </el-col>
        <el-col :span="6">
          <el-button type="success" round @click="addTimeDateForm">提交</el-button>
        </el-col>
      </el-row>
    </el-drawer>

    <!-- 宝宝日记_抽屉 -->
    <el-drawer
      :style="{ width: '154vh'}"
      title="宝宝日记"
      :visible.sync="baobaorijidrawer"
      direction="ltr"
      :before-close="baobaorijiClose"
      :destroy-on-close= "true"
    >
      <el-form
        :model="baobaorijiForm"
        :rules="timeDateFormRules"
        ref="timeForm"
        label-width="80px"
      >
        <el-form-item label="时间" required>
          <el-col :span="11">
            <el-form-item prop="date">
              <el-date-picker
              v-model="timeDateForm.date"
              type="date"
              placeholder="选择日期"
              size="mini"
              style="width: 90%"
              value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="1" style="margin-left: -12px;">-</el-col>
          <el-col :span="11">
            <el-form-item prop="time">
              <el-time-picker
              v-model="timeDateForm.time"
              placeholder="选择时间"
              size="mini"
              style="width: 90%"
              value-format="HH:mm:ss"
              ></el-time-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select
            v-model="timeDateForm.type"
            size="mini"
            placeholder="请选择类型"
          >
            <el-option label="日常" value="1"></el-option>
            <el-option label="里程" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="内容" prop="value">
          <el-input
          type="textarea"
            v-model="timeDateForm.value"
            size="mini"
            placeholder="试着写点什么..."
            style="width: 88%"
            :rows="6"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <el-upload
  action="#"
  list-type="picture-card"
  :auto-upload="false">
    <i slot="default" class="el-icon-plus"></i>
    <div slot="file" slot-scope="{file}">
      <img
        class="el-upload-list__item-thumbnail"
        :src="file.url" alt=""
      >
      <span class="el-upload-list__item-actions">
        <span
          class="el-upload-list__item-preview"
          @click="handlePictureCardPreview(file)"
        >
          <i class="el-icon-zoom-in"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleDownload(file)"
        >
          <i class="el-icon-download"></i>
        </span>
        <span
          v-if="!disabled"
          class="el-upload-list__item-delete"
          @click="handleRemove(file)"
        >
          <i class="el-icon-delete"></i>
        </span>
      </span>
    </div>
</el-upload>
<el-dialog :visible.sync="dialogVisible">
  <img width="100%" :src="dialogImageUrl" alt="">
</el-dialog>
        </el-form-item>
      </el-form>
      <div style="height: 1000px;">ss</div>
      <el-row type="flex" justify="center">
        <el-col :span="6">
          <el-button type="danger" round @click="handleClose">取消</el-button>
        </el-col>
        <el-col :span="6">
          <el-button type="success" round @click="addTimeDateForm">提交</el-button>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import { weather, getEchartsRender, addTimeDateForm, timeDateRender, updateTimeDateForm } from '@/api/Home'
export default {
  name: 'HomeIndex',
  computed: {
    pageClass () {
      return this.$route.meta.class // 从路由元数据中获取类名
    }
  },
  data () {
    return {
      birthdayTime: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      },
      myWeather: {
        city: '',
        humidity: '',
        reporttime: '',
        temperature: '',
        weather: '',
        winddirection: '',
        windpower: ''
      },
      timer: null, // 用于存储定时器
      // 星期几？
      dayOfWeek: '',
      echartsOneXData: [],
      echartsOneYData: [],
      echartsOneYDataChuCun: [],
      echartsOneYDataQinWei: [],
      // 时间线
      dateTimeHeightList: [],
      dateTimeWeightList: [],
      // 时间线——抽屉显示
      drawer: false,
      timeDateTitle: '添加记录',
      // 时间线表单
      timeDateForm: {
        date: '',
        time: '',
        type: '',
        value: ''
      },
      // 时间线表单校验
      timeDateFormRules: {
        date: [{ required: true, message: '请选择日期', trigger: 'blur' }],
        time: [{ required: true, message: '请选择时间', trigger: 'blur' }],
        type: [
          { required: true, message: '请选择身高或体重', trigger: 'change' }
        ],
        value: [{ required: true, message: '请填入数值', trigger: 'blur' }]
      },
      // 时间线分页
      timeDatePage: 1,
      timeDatePageSize: 6,
      // 时间线 无限滚动
      isBusy: false, // 防止重复加载
      hasMore: true, // 是否还有更多数据加载
      // 区分编辑和添加的临时变量
      timeDateIsEdit: false,
      timeDateIsEditId: '',
      // 宝宝日记
      baobaorijidrawer: false,
      baobaorijiForm: {},
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false

    }
  },
  mounted () {
    this.weather()
    this.startBirthdayTimer()
    this.getEchartsRender()
  },
  methods: {
    // 启动生日
    startBirthdayTimer () {
      this.updateBirthdayTime() // 初始调用
      this.timer = setInterval(this.updateBirthdayTime, 1000) // 每秒更新
    },
    updateBirthdayTime () {
      const startTime = new Date('2024-05-31T05:26:00') // 设置开始时间
      const now = new Date() // 获取当前时间
      const diff = now - startTime // 毫秒差
      this.birthdayTime = {
        days: Math.floor(diff / (1000 * 60 * 60 * 24)),
        hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((diff / (1000 * 60)) % 60),
        seconds: Math.floor((diff / 1000) % 60)
      }
    },
    // 获取天气
    async weather () {
      // 获取星期几
      this.getDayOfWeek()
      const res = await weather()
      this.myWeather = res.data.lives[0]
    },
    // 获取图标
    getWeatherIcon (weather) {
      switch (weather) {
        case '晴':
          return '#icon-a-caihongtianqihaotianqi'
        case '雨':
        case '小雨':
        case '中雨':
        case '大雨':
        case '暴雨':
        case '雷阵雨':
          return '#icon-tianqi-xiaoyu'
        case '多云':
          return '#icon-duoyun'
        case '阴':
          return '#icon-yin'
        case '雾':
        case '霾':
          return '#icon-dawu'
        case '雪':
        case '小雪':
        case '中雪':
        case '大雪':
        case '暴雪':
          return '#icon-xue'
        case '沙尘暴':
          return '#icon-shachenbao'
        // 可根据需要继续添加
        default:
          return '#icon-tianqi'
      }
    },
    // 获取星期几
    getDayOfWeek () {
      const date = new Date()
      const days = ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
      const res = days[date.getDay()] // 使用 getDay() 获取今天是周几，然后从数组中选取相应的中文表示
      this.dayOfWeek = res
    },
    // 初始化Echars
    initChartOne () {
      const chartDom = this.$refs.chartDomOne
      const myChart = this.$echarts.init(chartDom)
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow' // 显示阴影指示器
          },
          formatter: function (params) {
            let result = params[0].name + '<br>' // 显示 X 轴的标签（如年份）
            params.forEach(function (item) {
              result +=
                '<div><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                item.color +
                ';"></span>' +
                item.seriesName +
                ': ' +
                item.value +
                '</div>'
            })
            return result
          }
        },
        legend: {
          data: ['喝奶', '存储', '亲喂'], // 图例名称需要与系列名称一一对应
          selectedMode: 'multiple', // 允许多选，可以切换每个图例的显示状态
          top: '2%', // 可以设置图例的位置
          left: 'center' // 图例居中显示
        },
        xAxis: {
          type: 'category',
          data: this.echartsOneXData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '喝奶',
            type: 'bar',
            data: this.echartsOneYData,
            barGap: 0, // 0% 的间距表示各个系列的柱子紧挨着
            barWidth: '30%' // 柱子宽度
          },
          {
            name: '存储',
            type: 'bar',
            data: this.echartsOneYDataChuCun,
            barWidth: '30%' // 保持宽度一致
          },
          {
            name: '亲喂',
            type: 'bar',
            data: this.echartsOneYDataQinWei,
            barWidth: '30%' // 保持宽度一致
          }
        ]
      }
      myChart.setOption(option)
    },
    // 获取charts统计数据
    async getEchartsRender () {
      try {
        const resOne = await getEchartsRender(1)
        const { volume } = resOne.data
        // 初始化奶量统计图 喝奶量 — 日期
        // 日期
        this.echartsOneXData = volume.map((item) => item.time)
        // 喝奶量
        this.echartsOneYData = volume.map((item) =>
          item.total !== null ? item.total : 0
        )
        // 存储
        const resTwo = await getEchartsRender(2)
        const { volume: chuCun } = resTwo.data
        this.echartsOneYDataChuCun = chuCun.map((item) =>
          item.total !== null ? item.total : 0
        )
        // 亲喂
        const resThree = await getEchartsRender(3)
        const { volume: qinWei } = resThree.data
        this.echartsOneYDataQinWei = qinWei.map((item) =>
          item.total !== null ? item.total : 0
        )
        this.initChartOne()
        // 初始化 时间线
        this.timeDateRender()
      } catch (error) {
        console.dir(error)
      }
    },
    beforeDestroy () {
      if (this.timer) {
        clearInterval(this.timer) // 组件销毁时清除定时器
      }
    },
    // 添加时间线按钮
    addBtn () {
      this.drawer = true
    },
    // 添加时间线 抽屉确认关闭
    handleClose () {
      this.$confirm('确认要放弃添加？', '提示', {
        confirmButtonText: '离开',
        cancelButtonText: '再想想'
      })
        .then(() => {
          console.log('xxx')
          this.$message.warning('取消添加')
          this.drawer = false
          this.$refs.timeForm.resetFields()
          this.timeDateForm = ''
        })
        .catch(() => {
          this.$message.success('请继续编辑内容')
        })
    },
    // 时间线 抽屉表单提交
    addTimeDateForm () {
      this.$refs.timeForm.validate(async isOk => {
        try {
          const dateTime = this.timeDateForm.date + ' ' + this.timeDateForm.time
          if (this.timeDateIsEdit) {
            console.log('xxx')
            const res = await updateTimeDateForm(this.timeDateIsEditId, { ...this.timeDateForm, dateTime: dateTime })
            if (res.status === 200) {
              this.$message.success('恭喜，宝宝信息更改成功～ 🎉🎉🎉')
              this.drawer = false
              this.$refs.timeForm.resetFields()
            }
            this.timeDateIsEdit = false
          } else {
            const res = await addTimeDateForm({ ...this.timeDateForm, dateTime: dateTime })
            if (res.status === 200) {
              this.$message.success('恭喜，宝宝又长大啦～ 🎉🎉🎉')
              this.drawer = false
              this.$refs.timeForm.resetFields()
            }
          }
          this.timeDateForm = {
            date: '',
            time: '',
            type: '',
            value: ''
          }
          // 调取时间线 渲染
          this.timeDateRender()
        } catch (error) {
          console.dir(error)
        }
      })
    },
    // 时间线渲染
    async timeDateRender () {
      const res = await timeDateRender(1, 6)
      this.timeDateRenderFunction(res)
    },
    // 时间线渲染 时间格式化排序函数
    timeDateRenderFunction (res) {
      console.log(res)
      // 首先对返回的数据按照date进行降序排序
      // 身高数组
      this.dateTimeHeightList = res.data.data.height.sort((a, b) => new Date(b.babyDate) - new Date(a.babyDate)).map(item => ({
        ...item,
        babyDate: new Intl.DateTimeFormat('zh-CN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(new Date(item.babyDate))
      }))
      // 体重数组
      this.dateTimeWeightList = res.data.data.weight.sort((a, b) => new Date(b.babyDate) - new Date(a.babyDate)).map(item => ({
        ...item,
        babyDate: new Intl.DateTimeFormat('zh-CN', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        }).format(new Date(item.babyDate))
      }))
    },
    formatDate (dateStr) {
      return new Date(dateStr).toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      }).replace(/\//g, '-').replace(',', '')
    },
    // 时间线无限滚动
    async loadMoreData () {
      if (this.isBusy || !this.hasMore) return

      this.isBusy = true
      try {
        const res = await timeDateRender(this.timeDatePage, this.timeDatePageSize)
        if (res.data.data.height.length > 0 || res.data.data.weight.length > 0) {
          const formattedHeight = res.data.data.height.map(item => ({
            ...item,
            babyDate: this.formatDate(item.babyDate)
          }))
          const formattedWeight = res.data.data.weight.map(item => ({
            ...item,
            babyDate: this.formatDate(item.babyDate)
          }))

          this.dateTimeHeightList = this.dateTimeHeightList.concat(formattedHeight)
          this.dateTimeWeightList = this.dateTimeWeightList.concat(formattedWeight)
          this.timeDatePage++
        } else {
          this.hasMore = false // 没有更多数据
          this.$message.success('没有更多数据了～')
        }
      } catch (error) {
        console.error('加载数据失败', error)
      }
      this.isBusy = false
    },
    // 时间线 编辑-删除
    timeLinClick (item) {
      console.log(item)
      const { babyDate, babyType: type, babyValue: value, id } = item
      const { date, time } = this.splitAndFormatDateTime(babyDate)
      this.$confirm('是否编辑或删除数据？', '提示', {
        confirmButtonText: '操作',
        cancelButtonText: '取消'
      }).then(() => {
        this.drawer = true
        this.timeDateTitle = '编辑数据'
        this.timeDateForm = { date, time, type: type + '', value }
        // 设置临时 抽屉式存储还是添加
        this.timeDateIsEdit = true
        this.timeDateIsEditId = id
      })
        .catch(() => {
          this.$message.error('已取消 🐠🐠🐠')
        })
    },
    // 时间线 编辑回显 处理时间函数
    splitAndFormatDateTime (dateTimeStr) {
      const dateObj = new Date(dateTimeStr.replace(/\//g, '-')) // 将斜杠替换为破折号以兼容所有浏览器

      const dateFormatter = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
      const timeFormatter = new Intl.DateTimeFormat('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })

      return {
        date: dateFormatter.format(dateObj),
        time: timeFormatter.format(dateObj)
      }
    },
    baobaorijiAddBtn () {
      this.baobaorijidrawer = true
    },
    handleRemove (file) {
      console.log(file)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleDownload (file) {
      console.log(file)
    }
  }
}
</script>

<style scoped>
/* 天气卡片 */
.weather-card {
  margin: 2px 0px 10px 0;
  padding: 0; /* 去掉内边距 */
  width: 100%;
  text-align: center;
  background-color: #ffffff; /* 浅灰背景色 */
}

.weather-details-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px; /* 减小字体大小 */
  font-weight: 300;
}

.weather-details-display span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* 确保每个元素充满父容器的高度 */
  margin: 0; /* 移除可能存在的外边距 */
  padding: 0; /* 移除可能存在的内边距 */
}

/* 生日 */
.timer {
  display: flex;
  justify-content: space-around;
  padding: 12px 0;
  color: #ff8293;
}

.time-unit {
  display: flex;
  flex-direction: column; /* 关键：设置为列布局 */
  align-items: center; /* 水平居中对齐 */
  text-align: center;
}

.time-value {
  font-size: 36px;
  font-weight: bold;
}

.time-label {
  font-size: 14px;
  text-transform: uppercase;
}

/* Echars */
.chart-container {
  margin: 6px 0 0px 0;
}

/* 天气图标 */
.icon {
  width: 1.2em;
  height: 1.2em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

/* 添加按钮 */
.add-button {
  display: flex;
  justify-content: center;
  margin-top: -16px;
  margin-bottom: 8px;
}

/* 时间线 */
.timeLin {
  max-height: 399px;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-bottom: 26px;
}
::v-deep .el-timeline-item__timestamp {
    color: #909399;
    line-height: 1;
    font-size: 12px;
}
.timeLin .left{
  margin-right: 36px;
}

/* 宝宝日记 */
.block{
  margin-top: 16px;
}
.el-timeline {
  padding-left: 2px;
}
</style>
