<template>
  <div :class="['breastfeeding-container', pageClass]">
    <div class="statistic-container">
      <el-row :gutter="24">
        <!-- 喂奶间隔 -->
        <el-col :span="6">
          <el-statistic
            title="喂奶间隔"
          >
            <template slot="formatter">
              {{statisticsData.timing.timeSinceLastFeeding}}
            </template>
          </el-statistic>
        </el-col>
        <!-- 喂奶总量 -->
        <el-col :span="6">
          <el-statistic
            :value="statisticsData.todayDataSum"
            title="今日总量"
          >
          </el-statistic>
        </el-col>
        <!-- 喂奶次数 -->
        <el-col :span="6">
          <el-statistic
            :value="statisticsData.todayDataFrequency"
            title="今日次数"
          >
          </el-statistic>
        </el-col>
        <!-- 喂奶情况比例 -->
        <el-col :span="6">
          <el-statistic
            title="数据状态"
          >
            <template slot="formatter">
              <span v-if="statisticsData.isTrendPositive && !statisticsData.isTrendPositiveHint" style="font-style: italic;">向好</span>
              <span v-else-if="!statisticsData.isTrendPositiveHint" style="font-style: italic;">向差</span>
            </template>
            <template v-if="statisticsData.isTrendPositive && !statisticsData.isTrendPositiveHint" slot="prefix">
              <i class="iconfont icon-shangsheng" style="color: gold"></i>
            </template>
            <template v-else-if="!statisticsData.isTrendPositiveHint" slot="prefix">
              <i class="iconfont icon-xiajiang" style="color: red"></i>
            </template>

            <template v-if="statisticsData.isTrendPositiveHint" slot="formatter">
              <span style="font-style: italic;">等待</span>
            </template>
            <template v-if="statisticsData.isTrendPositiveHint" slot="prefix">
              <i class="el-icon-loading" style="color: purple"></i>
            </template>
          </el-statistic>
        </el-col>
      </el-row>
    </div>
    <el-button
      type="warning"
      icon="el-icon-plus"
      class="add-button"
      @click="submitAdd"
      >喂奶记录</el-button
    >
    <div class="records">
      <el-table
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="date" label="日期">
          <template v-slot="{row}">
            <el-time-picker
                v-if="row.isEdit"
                v-model="row.editRow.weinaiTime"
                placeholder="时间"
                style="width: 100%"
                value-format="HH-mm-ss"
                size="mini"
              ></el-time-picker>
            <span v-else>{{row.date}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="容量">
          <template v-slot="{row}">
            <el-input v-if="row.isEdit" size="mini" v-model="row.editRow.weinaiVolume"></el-input>
            <span v-else>{{row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="date" label="操作">
          <template v-slot="{ row }">
            <template v-if="row.isEdit">
              <el-button type="text" size="mini" @click="btnEditRecord(row)">确认</el-button>
              <el-button type="text" style="color: pink" size="mini" @click="row.isEdit = false">取消</el-button>
            </template>
            <template v-else>
              <el-button
                style="color: #f5cc00"
                type="text"
                size="mini"
                @click="btnEditRole(row)"
                >编辑</el-button
              >
              <el-popconfirm
                title="确认删除？"
                @confirm="del(row.id)"
              >
                <el-button
                  slot="reference"
                  type="text"
                  size="mini"
                  style="margin-left: 16px; color: #8d8ab8"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="chart-container">
      <!-- Echarts 图表的容器 -->
      <div ref="chartDomOne" style="width: 100%; height: 300px"></div>
      <div ref="chartDomTwo" style="width: 100%; height: 300px"></div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="90%"
      :append-to-body="true"
    >
      <el-form
        ref="weinaiForm"
        :model="weinaiForm"
        :rules="rules"
        label-width="90px"
      >
        <el-form-item label="选择时间" required>
          <el-col :span="11">
            <el-form-item prop="weinaiDate">
              <el-date-picker
                type="date"
                placeholder="日期"
                v-model="weinaiForm.weinaiDate"
                style="width: 100%"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col class="line" :span="1">-</el-col>
          <el-col :span="11">
            <el-form-item prop="weinaiTime">
              <el-time-picker
                placeholder="时间"
                v-model="weinaiForm.weinaiTime"
                style="width: 100%"
                value-format="HH-mm-ss"
              ></el-time-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="喝奶数量" prop="weinaiVolume">
          <el-input v-model.number="weinaiForm.weinaiVolume"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addWeinai">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import confetti from 'canvas-confetti'
import { weinai, renderPage, del, btnEditRecord, getEchartsRender } from '@/api/BottleFeeding.js'
export default {
  name: 'BottleFeeding',
  computed: {
    pageClass () {
      return this.$route.meta.class // 从路由元数据中获取类名
    }
  },
  data () {
    return {
      tableData: [],
      dialogVisible: false,
      weinaiForm: {
        weinaiDate: '',
        weinaiTime: '',
        weinaiVolume: ''
      },
      rules: {
        weinaiDate: [
          { required: true, message: '请选择日期', trigger: 'blur' }
        ],
        weinaiTime: [
          { required: true, message: '请选择时间', trigger: 'change' }
        ],
        weinaiVolume: [
          { required: true, message: '请输入奶量', trigger: 'blur' },
          { type: 'number', message: '奶量必须是数字值', trigger: 'blur' }
        ]
      },
      echartsOneXData: [],
      echartsOneYData: [],
      echartsTwoXData: [],
      echartsTwoYData: [],
      statisticsData: {
        timing: {
          lastFeedingTime: '', // 最后一次喂奶时间
          timeSinceLastFeeding: '' // 从最后一次喂奶到现在的时间间隔
        },
        // 今日总量 今日次数
        todayDataSum: '',
        todayDataFrequency: '',
        todayData: '',
        // 数据状态
        isTrendPositive: false,
        isTrendPositiveHint: false
      }
    }
  },
  mounted () {
    this.renderPage()
  },
  methods: {
    // 初始化Charts
    initChartOne () {
      const chartDom = this.$refs.chartDomOne
      const myChart = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '奶量统计图', // 图表标题
          left: 'center' // 标题居中
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross' // 显示十字准星指示器
          }
        },
        xAxis: {
          type: 'category',
          data: this.echartsOneXData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.echartsOneYData,
            type: 'line',
            smooth: true,
            // 设置线的颜色
            itemStyle: {
              color: '#f8d38a'
            },
            // 另一种设置颜色的方式
            lineStyle: {
              color: '#f8d38a'
            }
          }
        ]
      }
      myChart.setOption(option)
    },
    // 初始化Charts
    initChartTwo () {
      const chartDom = this.$refs.chartDomTwo
      const myChart = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '次数统计图', // 图表标题
          left: 'center' // 标题居中
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross' // 显示十字准星指示器
          }
        },
        xAxis: {
          type: 'category',
          data: this.echartsTwoXData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.echartsTwoYData,
            type: 'line',
            smooth: true,
            // 设置线的颜色
            itemStyle: {
              color: '#c1bcff'
            },
            // 另一种设置颜色的方式
            lineStyle: {
              color: '#c1bcff'
            }
          }
        ]
      }
      myChart.setOption(option)
    },
    // 设置表格颜色
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        if (Math.floor(rowIndex / 2) % 2 === 0) {
          return 'success-row' // 黄色背景
        } else {
          return 'warning-row' // 紫色背景warning
        }
      }
      return ''
    },
    // 添加记录打开dialog
    submitAdd () {
      this.dialogVisible = true
    },
    // 添加喂奶记录
    addWeinai () {
      this.$refs.weinaiForm.validate(async (isOk) => {
        try {
          if (isOk) {
            const { message } = await weinai({ ...this.weinaiForm, type: 1 })
            this.$message.success(message)
            this.dialogVisible = false
            this.$refs.weinaiForm.resetFields()
            this.renderPage()
            this.fireworks()
          }
        } catch (error) {
          console.dir(error)
        }
      })
    },
    // 获取页面数据
    async renderPage () {
      try {
        const res = await renderPage(1)
        // 首先对返回的数据按照startTime进行降序排序
        const sortedData = res.data.sort((a, b) => new Date(b.startTime) - new Date(a.startTime))
        // 接着格式化数据以适应表格
        this.tableData = sortedData.map(record => {
          return {
            ...record,
            date: this.formatDate(record.startTime), // 已格式化的时间
            name: record.volume, // 喂养量转换为字符串
            isEdit: false,
            editRow: {
              weinaiTime: this.formatDateTwo(record.startTime).toString() + '-00',
              weinaiVolume: record.volume
            }
          }
        })
        // 计算上次喂奶时间间隔
        this.statisticsData.timing.lastFeedingTime = new Date(sortedData[0].startTime) // 最新的喂奶时间
        this.updateTimeSinceLastFeeding() // 初始化时间间隔
        this.intervalId = setInterval(this.updateTimeSinceLastFeeding, 1000) // 每秒更新
        // 统计数据赋值
        this.statisticsData.todayDataSum = res.data.reduce((acc, item) => acc + (item.volume || 0), 0)
        this.statisticsData.todayDataFrequency = res.data.length
        // Echarts渲染
        this.getEchartsRender()
      } catch (error) {
        console.dir(error)
      }
    },
    // 时间日期格式化 一
    formatDate (datetime) {
      const date = new Date(datetime)
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return `${hours} : ${minutes}`
    },
    // 时间日期格式化 二
    formatDateTwo (datetime) {
      const date = new Date(datetime)
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return `${hours}-${minutes}`
    },
    // 删除数据
    async del (id) {
      try {
        const res = await del(id)
        this.$message.success(res.message)
        this.renderPage()
      } catch (error) {
        console.dir(error)
      }
    },
    // 弹出 编辑数据 输入框
    btnEditRole (row) {
      row.isEdit = true
    },
    // 编辑数据
    async btnEditRecord (row) {
      if (row.editRow.weinaiTime && row.editRow.weinaiVolume) {
        const { message } = await btnEditRecord({ ...row.editRow, id: row.id })
        this.$message.success(message)
        row.isEdit = false
        this.renderPage()
      } else {
        this.$message.error('修改内容不能为空 🐑')
      }
    },
    // 获取charts统计数据
    async getEchartsRender () {
      const res = await getEchartsRender(1)
      const { volume, frequency } = res.data
      this.dataAnalysis(volume, frequency)
      // 初始化奶量统计图 （第一张）
      this.echartsOneXData = volume.map(item => item.time)
      this.echartsOneYData = volume.map(item => item.total !== null ? item.total : 0)
      this.initChartOne()
      // 初始化次数统计图 （第二张）
      this.echartsTwoXData = frequency.map(item => item.time)
      this.echartsTwoYData = frequency.map(item => item.count !== null ? item.count : 0)
      this.initChartTwo()
    },
    // 烟花效果
    fireworks () {
      const duration = 15 * 1000
      const animationEnd = Date.now() + duration
      const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

      function randomInRange (min, max) {
        return Math.random() * (max - min) + min
      }

      const interval = setInterval(function () {
        const timeLeft = animationEnd - Date.now()

        if (timeLeft <= 0) {
          return clearInterval(interval)
        }

        const particleCount = 50 * (timeLeft / duration)
        // since particles fall down, start a bit higher than random
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 } })
        confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 } })
      }, 250)
    },
    // 数据统计 实现时间间隔更新函数
    updateTimeSinceLastFeeding () {
      const now = new Date()
      const diff = now - this.statisticsData.timing.lastFeedingTime // 时间差（毫秒）

      const hours = Math.floor(diff / 3600000)
      const minutes = Math.floor((diff % 3600000) / 60000)
      const seconds = Math.floor((diff % 60000) / 1000)

      this.statisticsData.timing.timeSinceLastFeeding = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      // this.statisticsData.timing.timeSinceLastFeeding = diff
    },
    // 数据分析 今日总量 今日次数 数据状态
    dataAnalysis (volumes, counts) {
      // 步骤1: 数据合并
      const mergedData = []
      const countsMap = counts.reduce((map, item) => {
        if (item.count >= 0) { // 确保计数是非负的
          map[item.time] = item.count
        }
        return map
      }, {})

      volumes.forEach(volume => {
        if (volume.total >= 0) { // 确保喝奶量是非负的
          const date = volume.time
          const totalVolume = volume.total
          const feedingsCount = countsMap[date] || 0 // 使用0替代null以保证计算有效
          mergedData.push({
            date,
            totalVolume,
            feedingsCount
          })
        }
      })

      // 计算长期平均
      const totalVolumeSum = mergedData.reduce((sum, item) => sum + item.totalVolume, 0)
      const totalCountSum = mergedData.reduce((sum, item) => sum + item.feedingsCount, 0)
      const longTermAvgVolume = totalVolumeSum / mergedData.length
      const longTermAvgCount = totalCountSum / mergedData.length

      // 步骤2: 计算增长率和得分
      let volumeGrowthSum = 0
      let countGrowthSum = 0
      let validDays = 0

      for (let i = 0; i < mergedData.length; i++) {
        const today = mergedData[i]

        // 使用长期平均来计算增长率
        if (longTermAvgVolume > 0 && longTermAvgCount > 0) {
          const volumeGrowth = (today.totalVolume - longTermAvgVolume) / longTermAvgVolume
          const countGrowth = (today.feedingsCount - longTermAvgCount) / longTermAvgCount
          volumeGrowthSum += volumeGrowth
          countGrowthSum += countGrowth
          validDays++
        }
      }

      if (validDays > 0) {
        const averageVolumeGrowth = volumeGrowthSum / validDays
        const averageCountGrowth = countGrowthSum / validDays

        const volumeWeight = 0.66 // 设定权重
        const countWeight = 0.34 // 设定权重

        const averageWeightedScore = (averageVolumeGrowth * volumeWeight) + (averageCountGrowth * countWeight)

        // 返回结果
        this.statisticsData.isTrendPositive = averageWeightedScore > 0
      } else {
        this.statisticsData.isTrendPositiveHint = true
      }
    },
    beforeDestroy () {
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
    }

  }
}
</script>

<style scoped>
.breastfeeding-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1;
}

.add-button {
  margin-bottom: 20px;
}

/* 覆盖 Element UI 按钮颜色 */
.el-button--warning {
  background-color: #f5cc00; /* 按钮背景颜色 */
  border-color: #f5cc00; /* 按钮边框颜色 */
}

.el-button--warning:focus,
.el-button--warning:hover {
  background: #f5cc00; /* 悬停时的背景颜色 */
  border-color: #f5cc00; /* 悬停时的边框颜色 */
}

.el-button--warning:active {
  background: #f5cc00; /* 激活/点击时的背景颜色 */
  border-color: #f5cc00; /* 激活/点击时的边框颜色 */
}

.records {
  margin-bottom: 20px;
}

.record {
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}

.chart-container {
  width: 100%;
  height: 300px;
}

.breastfeeding-container {
  position: relative;
  z-index: 1;
}

.add-button {
  margin-bottom: 20px;
}
::v-deep .el-overlay {
  z-index: 1000;
}

.statistic-container {
  margin-bottom: 18px;
}

/* 下面两个是statistic组件 */
::v-deep .el-statistic .title {
  font-weight: 500;
  font-size: 16px; /* 标题字体大小 */
}

::v-deep .el-statistic .con .number {
  font-size: 13px; /* 数值字体大小 */
}
</style>
