import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/utils/element-ui.js'
// 矢量图 导入svg带色彩使用
import '@/utils/icon/iconfont.js'
// 矢量图 font-class 引用
import '@/utils/icon/iconfont.css'

import '@/style/global.css'

// echarts
import echarts from '@/utils/echarts.js'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
