<template>
  <div :class="['breastfeeding-container', pageClass]">
    <div class="statistic-container">
      <el-row :gutter="24">
        <!-- 喂奶间隔 -->
        <el-col :span="6">
          <el-statistic title="亲喂间隔">
            <template slot="formatter">
              {{ statisticsData.timing.timeSinceLastFeeding }}
            </template>
          </el-statistic>
        </el-col>
        <!-- 喂奶总量 -->
        <el-col :span="6">
          <el-statistic :value="statisticsData.todayDataSum" title="今日总量">
          </el-statistic>
        </el-col>
        <!-- 喂奶次数 -->
        <el-col :span="6">
          <el-statistic
            :value="statisticsData.todayDataFrequency"
            title="今日次数"
          >
          </el-statistic>
        </el-col>
        <!-- 喂奶情况比例 -->
        <el-col :span="6">
          <el-statistic title="数据状态">
            <template slot="formatter">
              <span
                v-if="
                  statisticsData.isTrendPositive &&
                  !statisticsData.isTrendPositiveHint
                "
                style="font-style: italic"
                >向好</span
              >
              <span
                v-else-if="!statisticsData.isTrendPositiveHint"
                style="font-style: italic"
                >向差</span
              >
            </template>
            <template
              v-if="
                statisticsData.isTrendPositive &&
                !statisticsData.isTrendPositiveHint
              "
              slot="prefix"
            >
              <i class="iconfont icon-shangsheng" style="color: green"></i>
            </template>
            <template
              v-else-if="!statisticsData.isTrendPositiveHint"
              slot="prefix"
            >
              <i class="iconfont icon-xiajiang" style="color: red"></i>
            </template>

            <template
              v-if="statisticsData.isTrendPositiveHint"
              slot="formatter"
            >
              <span style="font-style: italic">等待</span>
            </template>
            <template v-if="statisticsData.isTrendPositiveHint" slot="prefix">
              <i class="el-icon-loading" style="color: red"></i>
            </template>
          </el-statistic>
        </el-col>
      </el-row>
    </div>
    <el-button
      type="danger"
      icon="el-icon-plus"
      class="add-button"
      @click="addBtn"
      >亲喂记录</el-button
    >
    <div v-if="showBubble" class="bubble-modal">
      <div class="breathing-bubble">
        <div>{{ elapsedTime }}</div>
        <el-form ref="addForm" :model="form" class="center-form" :rules="rules">
          <el-form-item prop="weinaiVolume">
            <el-select
              v-model="form.weinaiVolume"
              size="mini"
              placeholder="请选择存储量"
              style="width: 80%; margin-left: 10%"
            >
              <el-option
                v-for="num in 200"
                :key="num"
                :label="num"
                :value="num"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="center-buttons">
            <el-button type="danger" round size="small" @click="btnCancel"
              >取消</el-button
            >
            <el-button type="primary" round size="small" @click="stopTimer"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div v-if="formWidth" class="records">
      <el-table
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :cell-class-name="cellClassName"
      >
        <el-table-column prop="date" label="日期">
          <template v-slot="{ row }">
            <!-- 编辑模式下显示时间选择器 -->
            <div v-if="row.isEdit">
              <el-time-picker
                v-model="row.editRow.startTime"
                placeholder="开始"
                style="width: 100%; margin-bottom: 8px"
                value-format="HH-mm-ss"
                size="mini"
              ></el-time-picker>
              <el-time-picker
                v-model="row.editRow.endTime"
                placeholder="结束"
                style="width: 100%"
                value-format="HH-mm-ss"
                size="mini"
              ></el-time-picker>
            </div>
            <span v-else>{{ row.date }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="容量" >
          <template v-slot="{ row }">
            <el-input
              v-if="row.isEdit"
              size="mini"
              v-model="row.editRow.weinaiVolume"
            ></el-input>
            <span v-else>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="时长">
        </el-table-column>
        <el-table-column prop="date" label="操作">
          <template v-slot="{ row }">
            <template v-if="row.isEdit">
              <el-button type="text" size="mini" @click="btnEditRecord(row)"
                >确认</el-button
              >
              <el-button
                type="text"
                style="color: pink"
                size="mini"
                @click="row.isEdit = false"
                >取消</el-button
              >
            </template>
            <template v-else>
              <el-button style="color: #d667c6" type="text" size="mini" @click="btnEditRole(row)"
                >编辑</el-button
              >
              <el-popconfirm title="确认删除？" @confirm="del(row.id)">
                <el-button
                  slot="reference"
                  type="text"
                  size="mini"
                  style="margin-left: 10px; color: #e46c63"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else class="records">
      <el-table
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :cell-class-name="cellClassName"
      >
        <el-table-column prop="date" label="日期" width="140%">
          <template v-slot="{ row }">
            <!-- 编辑模式下显示时间选择器 -->
            <div v-if="row.isEdit">
              <el-time-picker
                v-model="row.editRow.startTime"
                placeholder="开始"
                style="width: 100%; margin-bottom: 8px"
                value-format="HH-mm-ss"
                size="mini"
              ></el-time-picker>
              <el-time-picker
                v-model="row.editRow.endTime"
                placeholder="结束"
                style="width: 100%"
                value-format="HH-mm-ss"
                size="mini"
              ></el-time-picker>
            </div>
            <span v-else>{{ row.date }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="容量" width="60%">
          <template v-slot="{ row }">
            <el-input
              v-if="row.isEdit"
              size="mini"
              v-model="row.editRow.weinaiVolume"
            ></el-input>
            <span v-else>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="时长" width="60%">
        </el-table-column>
        <el-table-column prop="date" label="操作">
          <template v-slot="{ row }">
            <template v-if="row.isEdit">
              <el-button type="text" size="mini" @click="btnEditRecord(row)"
                >确认</el-button
              >
              <el-button
                type="text"
                style="color: pink"
                size="mini"
                @click="row.isEdit = false"
                >取消</el-button
              >
            </template>
            <template v-else>
              <el-button style="color: #d667c6" type="text" size="mini" @click="btnEditRole(row)"
                >编辑</el-button
              >
              <el-popconfirm title="确认删除？" @confirm="del(row.id)">
                <el-button
                  slot="reference"
                  type="text"
                  size="mini"
                  style="margin-left: 10px; color: #e46c63"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="chart-container">
      <!-- Echarts 图表的容器 -->
      <div ref="chartDomOne" style="width: 100%; height: 300px"></div>
      <div ref="chartDomTwo" style="width: 100%; height: 300px"></div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import confetti from 'canvas-confetti'
import {
  chuCun,
  renderPage,
  del,
  btnEditRecord,
  getEchartsRender
} from '@/api/Kiss'
export default {
  name: 'KissIndex',
  computed: {
    pageClass () {
      return this.$route.meta.class // 从路由元数据中获取类名
    }
  },
  data () {
    return {
      tableData: [],
      // 计时弹窗窗
      showBubble: false,
      // 下面3个计时器
      startTime: null,
      elapsedTime: '00:00:00',
      timerInterval: null,
      form: {
        weinaiVolume: null
      },
      rules: {
        weinaiVolume: [
          { required: true, message: '请选择存储量', trigger: 'change' }
        ]
      },
      echartsOneXData: [],
      echartsOneYData: [],
      echartsTwoXData: [],
      echartsTwoYData: [],
      statisticsData: {
        timing: {
          lastFeedingTime: '', // 最后一次喂奶时间
          timeSinceLastFeeding: '' // 从最后一次喂奶到现在的时间间隔
        },
        // 今日总量 今日次数
        todayDataSum: '',
        todayDataFrequency: '',
        todayData: '',
        // 数据状态
        isTrendPositive: false,
        isTrendPositiveHint: false
      },
      formWidth: true
    }
  },
  mounted () {
    // 计时器是否存在
    this.updateTimerDisplay()
    // 获取页面数据
    this.renderPage()
    //  表格视频网页端
    this.updateColumnWidths()
    window.addEventListener('resize', this.updateColumnWidths)
  },
  methods: {
    // 初始化Charts
    initChartOne () {
      const chartDom = this.$refs.chartDomOne
      const myChart = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '奶量统计图', // 图表标题
          left: 'center' // 标题居中
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross' // 显示十字准星指示器
          }
        },
        xAxis: {
          type: 'category',
          data: this.echartsOneXData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.echartsOneYData,
            type: 'line',
            smooth: true,
            // 设置线的颜色
            itemStyle: {
              color: '#e46c63'
            },
            // 另一种设置颜色的方式
            lineStyle: {
              color: '#e46c63'
            }
          }
        ]
      }
      myChart.setOption(option)
    },
    // 初始化Charts
    initChartTwo () {
      const chartDom = this.$refs.chartDomTwo
      const myChart = this.$echarts.init(chartDom)
      const option = {
        title: {
          text: '次数统计图', // 图表标题
          left: 'center' // 标题居中
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross' // 显示十字准星指示器
          }
        },
        xAxis: {
          type: 'category',
          data: this.echartsTwoXData
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.echartsTwoYData,
            type: 'line',
            smooth: true,
            // 设置线的颜色
            itemStyle: {
              color: '#d667c6'
            },
            // 另一种设置颜色的方式
            lineStyle: {
              color: '#d667c6'
            }
          }
        ]
      }
      myChart.setOption(option)
    },
    // 设置表格颜色
    tableRowClassName ({ row, rowIndex }) {
      if (rowIndex % 2 === 1) {
        if (Math.floor(rowIndex / 2) % 2 === 0) {
          return 'success-row' // 黄色背景
        } else {
          return 'warning-row' // 紫色背景warning
        }
      }
      return ''
    },
    // 存储按钮
    addBtn () {
      this.showBubble = true
      this.startTimer()
    },
    // 取消添加
    btnCancel () {
      this.showBubble = false
      Cookies.remove('KissStartTime') // 清除Cookie
      this.$refs.addForm.resetFields()
    },
    // 添加存储记录
    stopTimer () {
      try {
        this.$refs.addForm.validate(async (isOK) => {
          if (isOK) {
            this.showBubble = false
            const res = await chuCun({
              startTime: Cookies.get('KissStartTime'),
              endTime: Date.now(),
              type: 3,
              weinaiVolume: this.form.weinaiVolume
            })
            if (res.status === 200) {
              this.$message.success(res.message)
              clearInterval(this.timerInterval)
              this.timerInterval = null
              Cookies.remove('KissStartTime') // 清除Cookie
              confetti({
                particleCount: 200,
                spread: 160,
                origin: { y: 0.6 }
              })
              this.elapsedTime = '00:00:00' // 重置显示的时间
              this.renderPage()
            }
          }
        })
      } catch (error) {
        console.dir(error)
      }
    },
    // 计时器开始
    startTimer () {
      let startTime = Cookies.get('KissStartTime')

      if (!startTime) {
        startTime = Date.now()
        Cookies.set('KissStartTime', startTime, { expires: 1 }) // 有效期为1天
      } else {
        startTime = parseInt(startTime)
      }

      this.startTime = startTime

      if (!this.timerInterval) {
        this.updateTime() // 立即更新一次时间，避免显示延迟
        this.timerInterval = setInterval(this.updateTime, 1000) // 每秒更新时间
      }
    },

    updateTime () {
      const now = Date.now()
      const elapsed = now - this.startTime
      this.elapsedTime = this.formatTime(elapsed)
    },
    updateTimerDisplay () {
      const startTime = Cookies.get('KissStartTime')
      if (startTime) {
        this.showBubble = true
        this.startTime = parseInt(startTime)
        this.updateTime() // 使用统一的更新时间方法

        if (!this.timerInterval) {
          this.timerInterval = setInterval(this.updateTime, 1000)
        }
      } else {
        this.elapsedTime = '00:00:00'
      }
    },
    // 计时器时间格式化
    formatTime (ms) {
      let seconds = Math.floor(ms / 1000)
      let minutes = Math.floor(seconds / 60)
      seconds %= 60
      const hours = Math.floor(minutes / 60)
      minutes %= 60
      return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
    },
    // 获取页面数据
    async renderPage () {
      try {
        const res = await renderPage(3)
        // 首先对返回的数据按照startTime进行降序排序
        const sortedData = res.data.sort(
          (a, b) => new Date(b.endTime) - new Date(a.endTime)
        )
        // 接着格式化数据以适应表格
        this.tableData = sortedData.map((record) => {
          return {
            ...record,
            date:
              this.formatDate(record.startTime) +
              '-' +
              this.formatDate(record.endTime), // 已格式化的时间
            duration: this.durationFunction(record.endTime, record.startTime),
            name: record.volume, // 喂养量转换为字符串
            isEdit: false,
            editRow: {
              startTime:
                this.formatDateTwo(record.startTime).toString() + '-00',
              endTime: this.formatDateTwo(record.endTime).toString() + '-00',
              weinaiVolume: record.volume
            }
          }
        })
        // 计算上次储存时间间隔
        this.statisticsData.timing.lastFeedingTime = new Date(
          sortedData[0].endTime
        ) // 最新的喂奶时间
        this.updateTimeSinceLastFeeding() // 初始化时间间隔
        this.intervalId = setInterval(this.updateTimeSinceLastFeeding, 1000) // 每秒更新
        // 统计数据赋值
        this.statisticsData.todayDataSum = res.data.reduce(
          (acc, item) => acc + (item.volume || 0),
          0
        )
        this.statisticsData.todayDataFrequency = res.data.length
        // Echarts渲染
        this.getEchartsRender()
      } catch (error) {
        console.dir(error)
      }
    },
    // 时间日期格式化
    formatDate (datetime) {
      const date = new Date(datetime)
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return `${hours}:${minutes}`
    },
    // 时间日期格式化 二
    formatDateTwo (datetime) {
      const date = new Date(datetime)
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return `${hours}-${minutes}`
    },
    // 时长函数
    durationFunction (endTime, startTime) {
      // 将字符串转换为Date对象
      const startDate = new Date(startTime)
      const endDate = new Date(endTime)

      // 计算时间差，单位为毫秒
      const timeDifference = endDate - startDate

      // 将时间差转换为分钟
      return Math.round(timeDifference / 1000 / 60)
    },
    // 表格数据高亮
    cellClassName ({ row, column, rowIndex, columnIndex }) {
      if (column.property === 'date') {
        return 'strong-text'
      }
      if (column.property === 'name' && Number(row.name) >= 0) {
        return 'red-text'
      }
      return ''
    },
    // 删除数据
    async del (id) {
      try {
        const res = await del(id)
        this.$message.success(res.message)
        this.renderPage()
      } catch (error) {
        console.dir(error)
      }
    },
    // 弹出 编辑数据 输入框
    btnEditRole (row) {
      row.isEdit = true
    },
    // 编辑数据
    async btnEditRecord (row) {
      if (
        row.editRow.startTime &&
        row.editRow.endTime &&
        row.editRow.weinaiVolume
      ) {
        const { message } = await btnEditRecord({
          weinaiVolume: row.editRow.weinaiVolume,
          startTimeStr: row.editRow.startTime,
          endTimeStr: row.editRow.endTime,
          id: row.id
        })
        this.$message.success(message)
        row.isEdit = false
        this.renderPage()
      } else {
        this.$message.error('修改内容不能为空 🐑')
      }
    },
    // 获取charts统计数据
    async getEchartsRender () {
      const res = await getEchartsRender(3)
      const { volume, frequency } = res.data
      this.dataAnalysis(volume, frequency)
      // 初始化奶量统计图 （第一张）
      this.echartsOneXData = volume.map((item) => item.time)
      this.echartsOneYData = volume.map((item) =>
        item.total !== null ? item.total : 0
      )
      this.initChartOne()
      // 初始化次数统计图 （第二张）
      this.echartsTwoXData = frequency.map((item) => item.time)
      this.echartsTwoYData = frequency.map((item) =>
        item.count !== null ? item.count : 0
      )
      this.initChartTwo()
    },
    // 数据统计 实现时间间隔更新函数
    updateTimeSinceLastFeeding () {
      const now = new Date()
      const diff = now - this.statisticsData.timing.lastFeedingTime // 时间差（毫秒）

      const hours = Math.floor(diff / 3600000)
      const minutes = Math.floor((diff % 3600000) / 60000)
      const seconds = Math.floor((diff % 60000) / 1000)

      this.statisticsData.timing.timeSinceLastFeeding = `${hours
        .toString()
        .padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')}`
      // this.statisticsData.timing.timeSinceLastFeeding = diff
    },
    // 数据分析 今日总量 今日次数 数据状态
    dataAnalysis (volumes, counts) {
      // 检查是否有今天的数据
      const latestVolume = volumes[volumes.length - 1]
      const latestCount = counts[counts.length - 1]

      if (latestVolume.total === 0 && latestCount.count === 0) {
        this.statisticsData.isTrendPositiveHint = true
        return
      }

      // 步骤1: 数据合并
      const mergedData = []
      const countsMap = counts.reduce((map, item) => {
        if (item.count >= 0) {
          // 确保计数是非负的
          map[item.time] = item.count
        }
        return map
      }, {})

      volumes.forEach((volume) => {
        if (volume.total >= 0) {
          // 确保喝奶量是非负的
          const date = volume.time
          const totalVolume = volume.total
          const feedingsCount = countsMap[date] || 0 // 使用0替代null以保证计算有效
          mergedData.push({
            date,
            totalVolume,
            feedingsCount
          })
        }
      })

      // 计算长期平均
      const totalVolumeSum = mergedData.reduce(
        (sum, item) => sum + item.totalVolume,
        0
      )
      const totalCountSum = mergedData.reduce(
        (sum, item) => sum + item.feedingsCount,
        0
      )
      const longTermAvgVolume = totalVolumeSum / mergedData.length
      const longTermAvgCount = totalCountSum / mergedData.length

      // 步骤2: 计算增长率和得分
      let volumeGrowthSum = 0
      let countGrowthSum = 0
      let validDays = 0

      for (let i = 0; i < mergedData.length; i++) {
        const today = mergedData[i]

        // 使用长期平均来计算增长率
        if (longTermAvgVolume > 0 && longTermAvgCount > 0) {
          const volumeGrowth =
            (today.totalVolume - longTermAvgVolume) / longTermAvgVolume
          const countGrowth =
            (today.feedingsCount - longTermAvgCount) / longTermAvgCount
          volumeGrowthSum += volumeGrowth
          countGrowthSum += countGrowth
          validDays++
        }
      }
      if (validDays > 0) {
        const averageVolumeGrowth = volumeGrowthSum / validDays
        const averageCountGrowth = countGrowthSum / validDays

        const volumeWeight = 0.66 // 设定权重
        const countWeight = 0.34 // 设定权重

        const averageWeightedScore =
          averageVolumeGrowth * volumeWeight + averageCountGrowth * countWeight

        // 返回结果
        this.statisticsData.isTrendPositive = averageWeightedScore > 0
      } else {
        this.statisticsData.isTrendPositiveHint = true
      }
    },
    // 表格——适配网页端
    updateColumnWidths () {
      const width = window.innerWidth
      if (width <= 390) {
        this.formWidth = false
      }
    },
    beforeDestroy () {
      // 组件销毁前清理定时器，防止内存泄漏
      if (this.timerInterval) {
        clearInterval(this.timerInterval)
      }
      if (this.intervalId) {
        clearInterval(this.intervalId)
      }
    }
  }
}
</script>

<style scoped>
.breastfeeding-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.add-button {
  margin-bottom: 20px;
}

.records {
  margin-bottom: 20px;
}

.record {
  background-color: #f9f9f9;
  border: 1px solid #e8e8e8;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
}

.chart-container {
  width: 100%;
  height: 300px;
}

.breastfeeding-container {
  position: relative;
  z-index: 1;
}

.add-button {
  margin-bottom: 20px;
}

.bubble-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* 视口宽度 */
  height: 100vh; /* 视口高度 */
  background-color: rgba(0, 0, 0, 0.6); /* 半透明遮罩层 */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* 确保在顶层 */
}

.breathing-bubble {
  width: 200px;
  height: 200px;
  background-color: #f69e9e;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: breathe 12s ease-in-out infinite;
}

@keyframes breathe {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.6);
  }
}

.center-form {
  text-align: center;
}

.center-buttons {
  display: flex;
  justify-content: center;
  gap: 10px; /* 调整按钮之间的间距 */
}

.center-buttons .el-button {
  margin: 0 5px; /* 如果需要更多的间距，可以调整这个值 */
}

/* 表格高亮 */
.red-text {
  font-weight: bold;
  color: rgb(206, 143, 255);
}
.strong-text {
  font-weight: bold;
}

.statistic-container {
  margin-bottom: 18px;
}

/* 下面两个是statistic组件 */
::v-deep .el-statistic .title {
  font-weight: 500;
  font-size: 16px; /* 标题字体大小 */
}

::v-deep .el-statistic .con .number {
  font-size: 13px; /* 数值字体大小 */
}
</style>
