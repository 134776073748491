// 喂奶记录
import request from '@/utils/request'

// 天气api
export function weather (data) {
  return request({
    url: '/weather/current'
  })
}

// 获取charts统计数据
export function getEchartsRender (type) {
  return request({
    url: '/feedingRecords/echartsRender/',
    params: {
      type
    }
  })
}

// 添加时间线
export function addTimeDateForm (data) {
  return request({
    url: '/babyInfo/add',
    method: 'POST',
    data
  })
}

// 时间线渲染
export function timeDateRender (page, pageSize) {
  return request({
    url: '/babyInfo/timeDateRender',
    params: {
      page, pageSize
    }
  })
}

// 更新时间线数据
export function updateTimeDateForm (id, data) {
  return request({
    url: `/babyInfo/update/${id}`, // 使用模板字符串构建 URL
    method: 'PUT',
    data
  })
}
